import React from 'react';
import { Link } from 'gatsby';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import TitleBottomLine from './TitleBottomLine';
import Card from './Card';
import arrow from '../assets/icons/cardArrow.svg';
import { PostPreview as PostsPreviewProps } from '../models/PostPreview';

const useStyles = makeStyles(() =>
  createStyles({
    container: { margin: '0 auto', maxWidth: 960, marginBottom: '10vh' },
    title: {
      paddingTop: 10,
      marginBottom: 0,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  })
);

interface Props {
  header: string;
  posts: PostsPreviewProps[];
}

const LongCards = ({ header, posts }: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <TitleBottomLine title={header} style={{ marginBottom: '4vh' }} />
      <div className={classes.container}>
        {posts.map(post => {
          return (
            <Link key="long-card" to={post.slug}>
              <Card
                style={{
                  padding: '15px 30px 15px 5vw',
                  marginBottom: 20,
                  textAlign: 'left',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div style={{ flexGrow: 1 }}>
                  <p className={classes.title}>
                    <b>{post.title}</b>{' '}
                  </p>
                  <span>{post.date}</span>{' '}
                  <span style={{ color: '#959390' }}>{post.author}</span>
                </div>
                <img src={arrow} alt="Arrow" />
              </Card>
            </Link>
          );
        })}
      </div>
    </>
  );
};

export default LongCards;
