import React from 'react';
import { Link } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
// import Image from 'gatsby-image';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { PostPreview as PostPreviewProps } from '../models/PostPreview';
// v4 com
// import options from '../utils/getRichTextOptions';

import { renderRichText } from "gatsby-source-contentful/rich-text"


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      background: '#eee',
      marginBottom: 15,
      [theme.breakpoints.up('sm')]: {
        width: (props: { vertical: string }): string =>
          props.vertical ? '100%' : '50%',
        marginBottom: 0,
      },

      height: 300,
    },
    txtIntro: {
      [theme.breakpoints.up('sm')]: {
        width: (props: { vertical: string }): string =>
          props.vertical ? '100%' : '47%',
      },
    },
    title: {
      fontSize: 22,
      fontWeight: 'bold',
      marginTop: '0px',
      color: 'black',
      '& a': {
        transition: 'all 0.3s ease-in',
      },
      [theme.breakpoints.up('md')]: {
        // minHeight: 70,
      },
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    category: {
      fontSize: '16px',
      color: '#FF6600',
      fontWeight: 500,
      marginTop: '0px',
      paddingTop: '10px',
      marginBottom: 10,
      // '&:hover': {
      //   textDecoration: 'underline',
      // },
    },
    timeandAuthor: {
      textTransform: 'capitalize',
      fontSize: 12,
      fontWeight: 500,
      marginBottom: 10,
      color: '#959390',
    },
    description: {
      // minHeight: 100,
      marginBottom: 10,
      display: '-webkit-box',
      WebkitLineClamp: 3,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      '& p': {
        fontSize: 16,
      },
      "& ul" : {
        listStyle:"none",
        margin:0
      }
    },
    noImage: {
      height: 150,
      background: 'gray',
      width: '100%',
    },
    link: {
      color: '#FF6600',
      fontWeight: 500,
      fontSize: 14,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  })
);

interface Props {
  post: PostPreviewProps;
  vertical: boolean;
  titleStyle?: string;
  hideDescription?: boolean;
  type?: string;
}

const Post = ({
  post,
  vertical,
  titleStyle,
  hideDescription,
  type,
}: Props): JSX.Element => {
  const classes = useStyles();

  const gatsbyImage = getImage(post.fluid)

  let descripcionJson = '';
  try {
    if (post.description.raw) {
      // eslint-disable-next-line prefer-destructuring
      // descripcionJson = `${post.description.raw.content[0].content[0].value}...`;
      descripcionJson = post.description.raw;
    }
  } catch (error) {
    descripcionJson = '';
  }

  return (
    <>
      {type !== 'no-image' && (
        <Link to={post.slug}>
          {post.fluid && (
            // <Image
            //   fluid={post.fluid}
            //   className={classes.img}
            //   style={vertical ? { width: '100%', height: 150 } : undefined}
            // />
            <GatsbyImage
              image={gatsbyImage}
              alt=""
              className={classes.img}
              style={vertical ? { width: '100%', height: 150 } : undefined}
            />

          )}
          {!post.fluid && <div className={classes.noImage}>&nbsp;</div>}
        </Link>
      )}
      <div
        className={classes.txtIntro}
        style={vertical ? { width: '100%' } : undefined}
      >
        {/* <Link to={`/tags/${post.categorySlug}`}> */}
        {post.category && (
          <h3
            className={classes.category}
            style={
              titleStyle === 'home'
                ? { borderBottom: '1px solid #959390', paddingBottom: 10 }
                : {}
            }
          >
            {post.category}
          </h3>
        )}
        {/* </Link> */}
        <Link to={post.slug}>
          <h1
            className={classes.title}
            style={
              (titleStyle === 'home' ? { marginBottom: 15 } : {},
              type === 'no-image'
                ? { borderBottom: '1px solid #959390', paddingBottom: 10 }
                : { borderBottom: 0 })
            }
          >
            {post.title && <>{post.title}</>}
          </h1>
        </Link>
        {!hideDescription && (
          <>
            {post.description && (
              <>
                {post.description.raw && (
                  
                  <div className={classes.description}>
                    {/* {documentToReactComponents(post.description.json, options)} */}
                    {/* {descripcionJson} */}
                    {renderRichText(post.description)}
                  </div>
                )}
                {!post.description.raw && (
                  <p className={classes.description}>{post.description}</p>
                )}
              </>
            )}
          </>
        )}
        {post.date && (
          <>
            <p className={classes.timeandAuthor}>
              {post.date}. {post.author}.
            </p>{' '}
          </>
        )}
        {titleStyle !== 'home' && (
          <Link to={post.slug}>
            <span className={classes.link}>Ver más</span>
          </Link>
        )}
      </div>
    </>
  );
};
Post.defaultProps = {
  titleStyle: '',
  hideDescription: false,
  type: '',
};
export default Post;
