import React from 'react';
import { Link } from 'gatsby';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import TitleBottomLine from './TitleBottomLine';
import { PostPreview as PostsPreviewProps } from '../models/PostPreview';

const useStyles = makeStyles(() =>
  createStyles({
    flashDestacado: {
      width: '100%',
      padding: '0 40px',
      background: (props: { barColor: string }): string => props.barColor,

      '& > div': {
        padding: '10px 6vw',
        background: 'white',
      },

      '& h1': {
        fontSize: 30,
        lineHeight: '34px',
        fontWeight: 500,
        marginTop: 0,
        marginBottom: 15,
        '&:hover': {
          textDecoration: 'underline',
        },
      },
      '& p': {
        fontSize: 16,
        lineHeight: '20px',
      },
    },
    dateAuthor: {
      textTransform: 'capitalize',
      color: '#002E63',
      fontSize: 12,
      fontWeight: 600,
      lineHeight: '16px',
    },
  })
);

interface Props {
  header: string;
  post: PostsPreviewProps;
  barColor: string;
}

const FeaturedFlash = ({ header, post, barColor }: Props): JSX.Element => {
  const props = { barColor };
  const classes = useStyles(props);

  return (
    <>
      <TitleBottomLine title={header} style={{ marginBottom: '10vh' }} />

      <div className={classes.flashDestacado} style={{ marginBottom: '10vh' }}>
        <div>
          <Link to={post.slug}>
            <h1>{post.title}</h1>
          </Link>
          <p style={{ textDecoration: 'none !important' }}>
            {post.description}
          </p>
          <small className={classes.dateAuthor}>
            {`${post.date} ${post.author}`}
          </small>
        </div>
      </div>
    </>
  );
};

export default FeaturedFlash;
