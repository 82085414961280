import React from 'react';
import Grid from '@material-ui/core/Grid';
import PostPreview from './PostPreview';
import TitleBottomLine from './TitleBottomLine';
import { PostPreview as PostsPreviewProps } from '../models/PostPreview';

interface Props {
  header: string;
  posts: PostsPreviewProps[];
  grid?: 3 | 4 | 6 | 12;
  titleStyle?: string;
  hideDescription?: boolean;
  type?: string;
}

const OtherPosts = ({
  header,
  posts,
  grid,
  titleStyle,
  hideDescription,
  type,
}: Props): JSX.Element => {
  return (
    <>
      <TitleBottomLine
        title={header}
        style={{ marginBottom: '40px' }}
        titleStyle={titleStyle}
      />

      <Grid container spacing={2} style={{ marginBottom: '10vh' }}>
        {posts &&
          posts.map(post => (
            <Grid key={post.slug} item md={grid}>
              <PostPreview
                post={post}
                vertical
                titleStyle={titleStyle}
                type={type}
                hideDescription={hideDescription}
              />
            </Grid>
          ))}
      </Grid>
    </>
  );
};

OtherPosts.defaultProps = {
  grid: 3,
  titleStyle: '',
  hideDescription: false,
  type: '',
};

export default OtherPosts;
