import * as React from 'react';
import CategoryLayout from '../components/CategoryLayout';
import FeaturedFlash from '../components/FeaturedFlash';
import FlashCards from '../components/FlashCards';
import OtherPosts from '../components/OtherPosts';
import LongCards from '../components/LongCards';
import {
  useFlashRentaFijaPosts,
  useFlashTecnicoPosts,
  useFlashEconomicoPosts,
  useFlashInternacionalPosts,
  useFlashEmisoraPosts,
  useCarteraModeloPosts,
  useCarteraTecnicoPosts,
  useOtrosArticulosPosts,
} from '../operations/queries';
import getCategoryColor from '../utils/getCategoryColor';

if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]', {
    offset: 50,
  });
}

export default function Tecnico(): JSX.Element {
  const flashEmisoraPosts = useFlashEmisoraPosts();
  const colorRV = getCategoryColor('rentaVariable');

  const flashTecnicoPosts = useFlashTecnicoPosts();
  const colorTecnico = getCategoryColor('tecnico');

  const flashEconomicoPosts = useFlashEconomicoPosts();
  const colorEcon = getCategoryColor('economico');

  const flashRentaFijaPosts = useFlashRentaFijaPosts();
  const colorRF = getCategoryColor('rentaFija');

  const flashInternacionalPosts = useFlashInternacionalPosts();
  const colorIntl = getCategoryColor('internacional');

  const carteraModeloPosts = useCarteraModeloPosts();

  const carteraTecnicoPosts = useCarteraTecnicoPosts();

  const otrosArticulos = useOtrosArticulosPosts();
  return (
    <CategoryLayout title="Flash">
      
      {/* <a data-scroll href="#emisora">Flash Emisora</a> */}
      <div id="emisora">
        <FeaturedFlash
          header="Flash Emisora"
          post={flashEmisoraPosts[0]}
          barColor={colorRV}
        />
        <FlashCards header="Flash Emisora" posts={flashEmisoraPosts} />
      </div>

      {/* <a data-scroll href="#tecnico">Flash Tecnico</a> */}
      <div id="tecnico">
        <FeaturedFlash
          header="Flash Técnico"
          post={flashTecnicoPosts[0]}
          barColor={colorTecnico}
        />
        <FlashCards header="Flash Técnico" posts={flashTecnicoPosts} />
      </div>

      {/* <a data-scroll href="#economico">Flash Economico</a> */}
      <div id="economico">
        <FeaturedFlash
          header="Flash Económico"
          post={flashEconomicoPosts[0]}
          barColor={colorEcon}
        />
        <FlashCards header="Flash Económico" posts={flashEconomicoPosts} />
      </div>

      {/* <a data-scroll href="#rentafija">Flash Renta Fija</a> */}
      <div id="rentafija">
        <FeaturedFlash
          header="Flash Renta Fija"
          post={flashRentaFijaPosts[0]}
          barColor={colorRF}
        />
        <FlashCards header="Flash Renta Fija" posts={flashRentaFijaPosts} />
      </div>

      {/* <a data-scroll href="#internacional">Flash Internacional</a> */}
      <div id="internacional">
        <FeaturedFlash
          header="Flash Internacional"
          post={flashInternacionalPosts[0]}
          barColor={colorIntl}
        />
        <FlashCards
          header="Flash Internacional"
          posts={flashInternacionalPosts}
        />
      </div>
      
      {/* <a data-scroll href="#carteramodelo">Cartera Modelo</a> */}
      <div id="carteramodelo">
        <LongCards header="Cartera Modelo" posts={carteraModeloPosts} />
      </div>

      {/* <a data-scroll href="#carteratecnico">Cartera Tecnico</a> */}
      {/* <div id="carteratecnico">
        <LongCards header="Cartera Técnico" posts={carteraTecnicoPosts} />
      </div> */}
      
      <OtherPosts header="Otros Artículos" posts={otrosArticulos} />
    </CategoryLayout>
  );
}
